import React , { useState , useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
  const colorArray = ['red' , 'blue' , 'orange' , 'aqua' , 'green' , 'pink']
  const iconArray = ['equalizer' , 'layers' , 'diamond' , 'share']
    const [company , setCompany] = useState({})
    const [ours , setOurs] = useState([])
    const [advantage , setAdvantage] = useState({})
    const [clients , setClients] = useState([])
    const [sliders , setSliders] = useState([])
    const [services , setServices] = useState([])
    const [lang  , setLang] = useState(localStorage.getItem('lang'))
    function removeHTML(str){ 
      var tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
  }
    useEffect(() => {
        const getLanguage = async () => {
            if(!localStorage.getItem('lang')){
            setLang(await localStorage.setItem('lang' , 'en'))
            }
        }
    const APICalls = async () => {
    const sliderResult = await fetch('https://algawhar.nativecourses.com/api/v1/sliders')
    const serviceResult = await fetch('https://algawhar.nativecourses.com/api/v1/services')
    const ourResult = await fetch('https://algawhar.nativecourses.com/api/v1/ours')
    const advantageResult = await fetch('https://algawhar.nativecourses.com/api/v1/advantage')
    const clientResult = await fetch('https://algawhar.nativecourses.com/api/v1/clients')
    const companyResult = await fetch('https://algawhar.nativecourses.com/api/v1/company')
    const sliders = await sliderResult.json()
    const services = await serviceResult.json()
    const ours = await ourResult.json()
    const advantage = await advantageResult.json()
    const clients = await clientResult.json()
    const company = await companyResult.json()
        setSliders(sliders)
        setServices(services)
        setOurs(ours)
        setAdvantage(advantage)
        setClients(clients)
        setCompany(company)
        }
        getLanguage()
        APICalls()
    } , [])
  return (
    <main role="main" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <section className="grid" style={{ direction:"ltr" }}>
        
          {
              sliders.length > 0 &&
              <div className="s-12 margin-bottom carousel-fade-transition carousel-main background-dark" style={{width: '100%'}}>
              <OwlCarousel
          className='owl-theme'
          loop={true}
          items={1}
          autoplay={true}
          autoplayTimeout={5000}
          autoplayHoverPause={true}
          nav={false}
          margin={20}
          >
            {
              sliders.map((value , key) => (
                <div className="item background-image" style={{backgroundImage:`url(https://dashboard.nativecourses.com/uploads/${value.image})`}} key={key}>
              <p className="text-padding text-strong text-white text-s-size-30 text-size-60 text-uppercase background-primary">{lang === "en" ? removeHTML(value.short_desc_en) : removeHTML(value.short_desc_ar)}</p>
              <br />
              <p className="text-padding text-size-20 text-dark text-uppercase background-white" style={{ direction: lang === "en" ? 'ltr' : 'rtl' }}>{lang === "en" ? removeHTML(value.long_desc_en) : removeHTML(value.long_desc_ar)}</p>
            </div>
              ))
            }
            
          </OwlCarousel>
          </div>  

          }
        </section>
        
        <section className="grid margin text-center">
          {

            services.map((value , key) => (
              <a href="/" className={`s-12 m-6 l-3 padding-2x vertical-center margin-bottom background-${colorArray[Math.floor(Math.random() * colorArray.length)]}`} key={key}>
              <i className={`icon-sli-${iconArray[Math.floor(Math.random() * iconArray.length)]} text-size-60 text-white center margin-bottom-15`}></i>
              <h3 className="text-strong text-size-20 text-line-height-1 margin-bottom-30 text-uppercase">{lang === "en" ? removeHTML(value.service_name_en) : removeHTML(value.service_name_ar)}</h3>
            </a>
            ))
          }
   
        </section>
        
        <section className="grid section margin-bottom background-dark">
        <div className="s-12 l-6">
          <img src={`https://dashboard.nativecourses.com/uploads/${advantage.image}`} alt={lang === "en" ? advantage.advantage_name_en : advantage.advantage_name_ar} />
          </div>
          <div className="s-12 l-6">
          <h2 className="s-12 l-6 center text-thin text-size-30 text-white text-uppercase margin-bottom-30">{lang === "en" ? advantage.advantage_name_en : advantage.advantage_name_ar}</h2>
          <p className="s-12 l-6 center"  dangerouslySetInnerHTML={{ __html: lang === "en" ? advantage.long_desc_en : advantage.long_desc_ar }}></p>
          </div>
        </section>
        
        {
          ours.map((value , key) => {
            if(key%2 === 0){
              return (
            <section className="grid margin" key={key}>
          <img className="s-12 m-6 margin-bottom" style={{ width: '100%' }} src={`https://dashboard.nativecourses.com/uploads/${value.image}`} alt="ourImage" />
        
          <div className="s-12 m-6 padding-2x margin-bottom background-blue">
            <h2 className="text-strong text-size-50 text-line-height-1">{lang === "en" ? value.our_name_en : value.our_name_ar}</h2>
            {lang === "en" ? removeHTML(value.long_desc_en) : removeHTML(value.long_desc_ar)}
          </div>
        </section>
              )}
              else{
                return (
            <section className="grid margin" key={key}>
          <div className="s-12 m-6 padding-2x margin-bottom background-blue">
            <h2 className="text-strong text-size-50 text-line-height-1">{lang === "en" ? value.our_name_en : value.our_name_ar}</h2>
           {lang === "en" ? removeHTML(value.long_desc_en) : removeHTML(value.long_desc_ar)}
          </div>
          <img className="s-12 m-6 margin-bottom" style={{ width: '100%' }} src={`https://dashboard.nativecourses.com/uploads/${value.image}`} alt="ourImage" />
        </section>
                )
              }
})
        }
        
        <h2 className="s-12 text-white text-thin text-size-30 text-white text-uppercase margin-top-bottom-40 center text-center">{lang === "en" ? "Top" : ""} <b>{lang === "en" ? "Clients" : "عملائنا"}</b></h2>
        <section className="grid margin">
          {
            clients.map((value , key) => (
              <a className="s-12 m-6 margin-bottom" target={`_blank`} href={value.sector} key={key}>
                <h2>{lang === "en" ? value.client_name_en : value.client_name_ar}</h2>
            <img className="full-img" src={`https://dashboard.nativecourses.com/uploads/${value.logo}`} alt=""/>
          </a>	
            ))
          }
          
        </section>
        
        <section className="grid margin text-center">
          <div className="m-12 l-4 padding-2x background-dark margin-bottom text-right">
            <h3 className="text-strong text-size-25 text-uppercase margin-bottom-10">{lang === "en" ? "Let's keep in touch" : "ابق علي تواصل معنا"}</h3>
          </div>
          <a href={company.facebook} target={`_blank`} className="s-12 m-6 l-2 padding vertical-center margin-bottom facebook hover-zoom">
             <i className="icon-sli-social-facebook text-size-60 text-white center"></i>
          </a>
          <a href={company.youtube} target={`_blank`} className="s-12 m-6 l-2 padding vertical-center margin-bottom twitter hover-zoom">
            <i className="icon-sli-social-twitter text-size-60 text-white center"></i>
          </a>
          <a href={company.instagram} target={`_blank`} className="s-12 m-6 l-2 padding vertical-center margin-bottom linkedin hover-zoom">
            <i className="icon-sli-social-linkedin text-size-60 text-white center"></i>
          </a>
        </section>
                
      </main>
  )
}

export default Home